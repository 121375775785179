/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import React from 'react';
import { Fragment, useState } from 'react'

const footerNavigation = {
  account: [
    { name: 'Find vintage wears from stores in your community.', href: '#' },
    // { name: 'Saved Items', href: '#' },
    // { name: 'Orders', href: '#' },
    // { name: 'Redeem Gift card', href: '#' },
  ],
  service: [
    // { name: 'Pricing', href: '/pricing' },
    { name: 'Store Application', href: '/store-application' },
    // { name: 'FAQ', href: '#' },
    // { name: 'Find a store', href: '#' },
    // { name: 'Get in touch', href: '#' },
  ],
  company: [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms & Conditions', href: '/terms-and-conditions' },
    // { name: 'Careers', href: '#' },
    // { name: 'Terms & Conditions', href: '#' },
    // { name: 'Privacy', href: '#' },
  ],
  connect: [
    // { name: 'Facebook', href: '#' },
    { name: 'Instagram', href: 'https://www.instagram.com/vintagewears.io/' },
    // { name: 'Pinterest', href: '#' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Footer() {
  const [open, setOpen] = useState(false)

  return (
    <footer aria-labelledby="footer-heading" className="bg-white">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 border-t border-gray-200 py-20 sm:grid-cols-2 sm:gap-y-0 lg:grid-cols-4">
          <div className="grid grid-cols-1 gap-y-10 lg:col-span-2 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-0">
            <div>
              <h3 className="text-sm font-medium text-gray-900">Vintage Wears</h3>
              <ul role="list" className="mt-6 space-y-6">
                {footerNavigation.account.map((item) => (
                  <li key={item.name} className="text-sm">
                    <a href={item.href} className="text-gray-500 hover:text-gray-600">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-900">Stores</h3>
              <ul role="list" className="mt-6 space-y-6">
                {footerNavigation.service.map((item) => (
                  <li key={item.name} className="text-sm">
                    <a href={item.href} className="text-gray-500 hover:text-gray-600">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-10 lg:col-span-2 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-0">
            <div>
              <h3 className="text-sm font-medium text-gray-900">Legal</h3>
              <ul role="list" className="mt-6 space-y-6">
                {footerNavigation.company.map((item) => (
                  <li key={item.name} className="text-sm">
                    <a href={item.href} className="text-gray-500 hover:text-gray-600">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-900">Connect</h3>
              <ul role="list" className="mt-6 space-y-6">
                {footerNavigation.connect.map((item) => (
                  <li key={item.name} className="text-sm">
                    <a href={item.href} className="text-gray-500 hover:text-gray-600">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-100 py-10 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center justify-center text-sm text-gray-500">
            <div className="max-w-40">
              <a href="https://apps.apple.com/us/app/vintage-wears/id6447313658">
                <img
                  src="https://vintage-wear-assets.s3.amazonaws.com/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </a>
            </div>
          </div>
          <p className="mt-6 text-center text-sm text-gray-500 sm:mt-0">&copy; 2022 Vintage Wears</p>
        </div>
      </div>
    </footer>
  );
}

import React from 'react';
import { connect } from 'react-redux';

import NavBar from '../../Navbar';
import ProductsGrid from './ProductsGrid';
import Footer from '../../Footer';

import { getProducts } from '../../../apis/products';

const mapStateToProps = (state) => ({
  userSignedIn: state.userSignedIn,
  userStoreAdmin: state.userStoreAdmin
});
const mapDispatchToProps = (dispatch) => ({});

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      previousPage: '',
      nextPage: ''
    };

    this.previousPageHandler = this.previousPageHandler.bind(this);
    this.nextPageHandler = this.nextPageHandler.bind(this);
  }

  componentDidMount() {
    getProducts()
      .then((response) => {
        this.setState({
          products: response.data.items,
          previousPage: response.data.previousPage,
          nextPage: response.data.nextPage
        });
      })
      .catch(() => {
        // Todo: Display error message
      });
  }

  previousPageHandler() {
    const { previousPage } = this.state;

    getProducts({ page: previousPage })
      .then((response) => {
        this.setState({
          products: response.data.items,
          previousPage: response.data.previousPage,
          nextPage: response.data.nextPage
        });
      })
      .then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
      .catch(() => {
        // Todo: Display error message
      });
  }

  nextPageHandler() {
    const { nextPage } = this.state;

    getProducts({ page: nextPage })
      .then((response) => {
        this.setState({
          products: response.data.items,
          previousPage: response.data.previousPage,
          nextPage: response.data.nextPage
        });
      })
      .then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
      .catch(() => {
        // Todo: Display error message
      });
  }

  render() {
    const { userSignedIn, userStoreAdmin } = this.props;
    const { products, previousPage, nextPage } = this.state;

    return (
      <React.Fragment>
        <NavBar userSignedIn={userSignedIn} userStoreAdmin={userStoreAdmin} />
        <ProductsGrid
          products={products}
          previousPage={previousPage}
          previousPageHandler={this.previousPageHandler}
          nextPage={nextPage}
          nextPageHandler={this.nextPageHandler}
        />
        <Footer />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Products);
